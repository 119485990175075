<template>
	<div id="app">
		<v-app>
			<NavBar></NavBar>
			<v-main>
				<transition :name="$route.meta.transition" mode="out-in">
					<router-view class="page-component" @transition="changeTransition"/>
				</transition>
			</v-main>
		</v-app>
	</div>
</template>

<script>
import NavBar from "@/components/NavBar";

export default {
	name: 'App',
	components: {
		NavBar
	},
	computed: {
		user() {
			return this.$store.state.user
		},
		balance() {
			return this.$store.state.cas_pratique.balance
		}
	},
	methods: {
		data: () => ({
			transitionName: "slide",
		}),
		changeTransition(value) {
			this.transitionName = "transition" + value;
		},

	},
	watch: {
		$route(to) { //utiliser pour chaque route
			document.title = to.meta.title || "SEO-ELP";
		},
	},
	created() {
		var user = this.$cookies.get("currentUser")
		if (user === null) user = {}
		this.$store.commit("setUser", user);
	}
};

</script>

<style lang="scss">

@import "./assets/scss/_variables.scss";

table{
	width: 100%;
}

.w-50 {width: 50%;}
.w-25 {width: 25%;}
.w-100{width: 100%;}

img{
	max-width: 100%;
}

.border{
	border: 1px solid black;
}

#app {
	width: 100vw;
	height: 100vh;
}

.primary-color {
	color: $primary-color;
}

.secondary-color {
	color: $secondary-color;
}

.attention-color {
	color: $attention-color;
}

.alert-color {
	color: $alert-color;
}

.big-font {
	font-family: $big-font;
}

.med-font {
	font-family: $med-font;
}


/* transition Cours */

.transitioncours-leave {
	transition: .3s;
	transform: translateX(0);
}

.transitioncours-leave-to {
	transition: .3s;
	transform: translateX(-100vw);
}

.transitioncours-enter {
	transition: .3s;
	transform: translateX(200vw);
}

.transitioncours-enter-to {
	transition: .3s;
	transform: translateX(0);
}

/* transition Exercices */

.transitionexercices-leave {
	transition: .3s;
	transform: translateX(0);
}

.transitionexercices-leave-to {
	transition: .3s;
	transform: translateX(-100vw);
}

.transitionexercices-enter-to {
	transition: .3s;
	transform: translateY(0);
}

.transitionexercices-enter {
	transition: .3s;
	transform: translateY(200vw);
}


/* transition Notes */

.transitionclasses-leave {
	transition: .3s;
	transform: translateY(0);
}

.transitionclasses-leave-to {
	transition: .3s;
	transform: translateY(100vw);
}

.transitionclasses-enter {
	transition: .3s;
	transform: translateX(200vw);
}

.transitionclasses-enter-to {
	transition: .3s;
	transform: translateX(0);
}

/* transition Classes */

.transitionnotes-leave {
	transition: .3s;
	transform: translateY(0);
}

.transitionnotes-leave-to {
	transition: .3s;
	transform: translateY(100vw);
}

.transitionnotes-enter {
	transition: .3s;
	transform: translateX(200vw);
}

.transitionnotes-enter-to {
	transition: .3s;
	transform: translateX(0);
}


/* transition Caspratique (id) */

.transitionCp-leave {
	transition: .3s;
	transform: translateY(0);

}

.transitionCp-leave-to {
	transition: .3s;
	transform: translateY(200vh);
}

.transitionCp-enter {
	transition: .3s;
	transform: translateY(200vh);
}

.transitionCp-enter-to {
	transition: .3s;
	transform: translateY(0);
}

/* transition Caspratique Note */

.transitionCpNote-leave {
	transition: .3s;
	transform: translateY(0);
}

.transitionCpNote-leave-to {
	transition: .3s;
	transform: translateY(200vh);
}

.transitionCpNote-enter {
	transition: .3s;
	transform: translateY(200vh);
}

.transitionCpNote-enter-to {
	transition: .3s;
	transform: translateY(0);
}


//@include sm {
//	#nav {
//		padding: 8px 2% 1em 4%;
//
//		#nav-logo {
//			font-size: 1em;
//			width: 30%;
//		}
//
//		.connexion {
//			font-size: 1em;
//			padding: 7px 17px;
//		}
//	}
//}
//
//@include lg {
//	#nav {
//		padding: 15px 5% 1em 5%;
//
//		#nav-logo {
//			font-size: 1.8em;
//			width: 15%;
//		}
//
//		.connexion {
//			font-size: 1.3em;
//			padding: 10px 24px;
//		}
//	}
//}


/* GENERAL */
h1,.h1 {
	font-size: 2.5em;
}

h2,.h2{
	font-size: 2em;
}

h3,.h3 {
	font-family: $big-font;
	font-size: 1.75em
}

h4,.h4 {
	font-family: $big-font;
	font-size: 1.5em;
}

h5 {
	font-family: $big-font;
}


.contact,
.home {
	position: absolute;
}

body {
	margin: 0;
	padding: 0;


}

* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
}


/* SCROLL BAR */

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	border-radius: 10px;
	background: rgba($color: $primary-color, $alpha: 0);
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: $primary-color;
}

.home.transitioncontact {
	&-enter-active {
		transition: all 0.7s ease;
	}

	&-leave-active {
		transition: all 0.7s ease;

		.baseAc {
			transition: all 0.4s ease;
		}

		.cta-btn {
			transition: all 0.3s ease;
			transition-delay: 0.4s;
		}
	}

	&-enter-active {
		.baseAc {
			h1 {
				transition-delay: 0.4s;
			}
		}
	}

	&-leave-to {
		.baseAc {
			transform: translateY(50vh);
			opacity: 0;
		}

		.cta-btn {
			transform: translateY(40px);
			opacity: 0;
		}
	}

	&-enter {
		transform: translateY(-20vh);
		opacity: 0;
	}
}

/* ANIMATION DE L'ACCUEIL */

.home.transitioncontact {
	&-enter-active {
		transition: all 0.7s ease;
	}

	&-leave-active {
		transition: all 0.7s ease;

		.baseAc {
			transition: all 0.4s ease;
		}

		.cta-btn {
			transition: all 0.3s ease;
			transition-delay: 0.4s;
		}
	}

	&-enter-active {
		.baseAc {
			h1 {
				transition-delay: 0.4s;
			}
		}
	}

	&-leave-to {
		.baseAc {
			transform: translateY(50vh);
			opacity: 0;
		}

		.cta-btn {
			transform: translateY(40px);
			opacity: 0;
		}
	}

	&-enter {
		transform: translateY(-20vh);
		opacity: 0;
	}
}

.contact.transitioncontact {
	&-enter-active {
		transition: all 0.8s ease;

		aside {
			transition: all 0.4s ease-in-out;
		}

		.form-wrapper {
			transition: all 0.4s ease;
			transition-delay: 0.2s;
		}
	}

	&-leave-active {
		transition: all 0.8s ease;

		aside {
			transition: all 0.4s ease-out;
			transition-delay: 0.4s;
		}

		.form-wrapper {
			transition: all 0.4s ease;
		}
	}

	&-leave-to {
		.form-wrapper {
			transform: translateY(50vh);
			opacity: 0;
		}

		aside {
			transform: translateX(-100vw);
		}
	}

	&-enter {
		aside {
			transform: translateX(-100vw);
		}

		.form-wrapper,
		#id {
			transform: translateY(50vh);
			opacity: 0;
		}
	}
}

/*
@media screen and (max-width: 1920px) {
  #nav .connexion {
    font-size: 1.1em;
    padding: 12px 20px;
  }
  #nav-logo {
    font-size: 14px;
  }

}*/

@media screen and (min-width: 990px) and (max-width: 1200px) {
	#nav #nav-logo {
		font-size: 1.4em;
	}
}


</style>
