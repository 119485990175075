import Vue from 'vue'
import Vuex from 'vuex'
// import gsap from 'gsap'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const getInitState = () => ({
	state: {
		user: {},
		idCours: 0,
		qcm: {
			chrono: {
				min: 0,
				sec: 0
			}
		},
		dateDebut: null,
		cp_res: [],
		cas_pratique: {
			start: 0,
			balance: 600,
			points: 0,
			score: 0,
			keyword: "",
			marque: "",
			date: new Date(new Date().getTime() + 7 * 60000),
		}
	}
})

const store = new Vuex.Store({
	...getInitState(),
	mutations: {
		updateCours(state, id) {
			if (state.idCours !== id)  state.cas_pratique.start = 0;
			state.idCours = id;
		},
		setUser(state, user) {
			state.user = user
		},
		
		setBalance(state, balance) {
			state.cas_pratique.balance = balance
		},
		removeBalance(state, balance) {
			state.cas_pratique.balance -= balance
		},
		giveBalance(state, balance) {
			state.cas_pratique.balance += balance
		},
		
		setPoints(state, {score,note}) {
			state.cas_pratique.points = note
			state.cas_pratique.score = score
		},
		
		setDate(state, date) {
			state.cas_pratique.date = date
		},
		
		setChrono(state, chrono) {
			state.qcm.chrono.min = chrono[0];
			state.qcm.chrono.sec = chrono[1];
		},
		
		initVar2(state,res) {
			state.cp_res=res;
			
			state.start = 0;
			state.date = new Date(new Date().getTime() + 7 * 60000);
			state.balance = res.argent;
			state.cas_pratique.balance = res.argent;
			state.points = 0;
			state.cas_pratique.start = 1;
			state.cas_pratique.date = new Date(new Date().getTime() + 10*60000);//chrono de 10 min
			state.cas_pratique.points =0;
		},
		
		initVariables(state,argent) {
			state.start = 0;
			
			state.date = new Date(new Date().getTime() + 7 * 60000);
			state.balance = argent?argent:600;
			state.cas_pratique.balance = argent?argent:600;
			state.points = 0;
			state.dateDebut=null;
			
			
			state.cas_pratique.start = 1;
			state.cas_pratique.date = new Date(new Date().getTime() + 10*60000);//chrono de 10 min
			if (state.user.tiersTemps) state.cas_pratique.date = new Date(new Date().getTime() + 13*60000+33000);//chrono de 13 min et 20 sec
			state.cas_pratique.points =0;
			state.cas_pratique.score =0;
		},
		
		resetState(state) {
			Object.assign(state, getInitState())
		}
	},
	plugins: [createPersistedState({
		paths: ['cas_pratique', 'qcm','cp_res','idCours']
	})],
})
// store.commit('initVariables')

export default store
