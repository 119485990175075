var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_vm._m(0),_c('div',{staticClass:"infos"},[_c('div',{staticClass:"infos_wrapper"},[_c('ul',[_c('h4',[_vm._v("Nos partenaires")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('router-link',{attrs:{"to":"Contact"}},[_vm._v("Devenir partenaire")])],1),_c('ul',[_c('h4',[_vm._v("Contact")]),_c('router-link',{attrs:{"to":"Contact"}},[_vm._v("Contactez-nous")])],1),_c('ul',{staticStyle:{"width":"33%"}},[_c('h4',[_vm._v("Mentions légales")]),_c('li',[_c('router-link',{attrs:{"to":"CGU"}},[_vm._v("CGU")])],1),_c('br'),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"partenaires"},[_c('ul',[_c('li',[_c('img',{attrs:{"src":require("../assets/elements/part_esta.jpg"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../assets/elements/part_freduc.jpg"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../assets/elements/part_iutbm.jpg"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../assets/elements/part_mmi.jpg"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../assets/elements/part_elliadd.jpg"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":"https://upload.wikimedia.org/wikipedia/commons/3/32/Logo_Bourgogne-Franche-Comt%C3%A9_2016-11.png"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://www.esta-groupe.fr/","target":"_blank"}},[_vm._v("Esta Groupe")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://www.univ-fcomte.fr/structures-federatives/fr-educ","target":"_blank"}},[_vm._v("FR-EDUC")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"http://elliadd.univ-fcomte.fr/","target":"_blank"}},[_vm._v("Laboratoire ELLIADD")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"http://www.iut-bm.univ-fcomte.fr/","target":"_blank"}},[_vm._v("IUT BM")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"http://mmimontbeliard.com/","target":"_blank"}},[_vm._v("DUT MMI")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"rel":"license","href":"http://creativecommons.org/licenses/by-nc/4.0/"}},[_c('img',{staticStyle:{"border-width":"0"},attrs:{"alt":"Licence Creative Commons","src":"https://i.creativecommons.org/l/by-nc/4.0/88x31.png"}})]),_c('br'),_c('span',{staticStyle:{"font-size":"0.7em"}},[_vm._v("Cette œuvre est mise à disposition selon les termes de la "),_c('a',{attrs:{"rel":"license","href":"http://creativecommons.org/licenses/by-nc/4.0/"}},[_vm._v("Licence Creative Commons Attribution - Pas d’Utilisation Commerciale 4.0 International")])])])
}]

export { render, staticRenderFns }