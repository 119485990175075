<template>
	<div>
		<div class="coursWrapper test" v-if="user.username !== '0'">

			<h1 style="text-align: center">Mes cours</h1>

			<div style="display: flex" v-if="user.role !== 'ETUDIANT'">
				<div style="border: black 1px solid; margin: 5px; width: 100%; padding: 10px" v-if="user.role==='ADMIN'">
					Ajouter un module : <v-btn @click="creerCP">Ajouter</v-btn>
					<v-text-field v-model="libelleModule" label="Nom du cours" />
					<v-radio-group row style="margin: -10px 0" v-model="typeModule">
						<v-radio label="Cours" value="2" />
						<v-radio label="Cas pratique" value="3" />
					</v-radio-group>
				</div>
				<div style="border: black 1px solid; margin: 5px; width: 100%; padding: 10px">
					Ajouter un cas pratique ou un cours : <v-btn @click="creerNiveau">Ajouter</v-btn><br>
					<v-select label="Selectionner le module" :items="listeModuleCours"
							  item-text="libelle" return-object v-model="sousModuleActu" />
				</div>
				<div style="border: black 1px solid; margin: 5px; width: 100%; padding: 10px" v-if="user.role==='ADMIN'">
					Dupliquer cours ou cas pratique : <v-btn @click="dupliquerNiveau">Ajouter</v-btn><br>
					<v-select label="Cas pratique ou cours source" :items="listeCoursEtCP"
							  item-text="libelle" return-object v-model="niveauActu" dense/>
					<v-select label="Module destination" :items="listeModuleCours"
							  item-text="libelle" return-object v-model="sousModuleActu" dense/>
				</div>
			</div>
			<div v-if="user.role==='FORMATEUR'">
				<span style="height: 20px; width: 20px; background-color: orangered; display: inline-block; margin: 5px 10px"></span>
				<span style="margin: auto 0">Vos cours sont en rouge.</span>
			</div>

			<div class="my-3" v-for="(module,index) in modules" :key="index">
				<div>
					<span class="nomModule">{{ module.libelle }}</span>
				</div>
<!--				<div v-if="module.sousModules.length===0 && module.cours[0] " class="module" style="padding-bottom: 10px">-->
<!--					<span @click="changeRoute(0,module.cours[0])" class="lien" style="margin-left: 20px" v-if="user.role === 'ADMIN' || module.cours[0].actif || user.idUser===module.cours[0].idFormateur">-->
<!--						{{ module.cours[0].libelle }}-->
<!--					</span>-->
<!--				</div>-->

				<v-expansion-panels class="module">
					<v-expansion-panel v-for="(sousmodule,index2) in module.sousModules" :key="index2">
						<v-expansion-panel-header>
							<span style="font-size: 1.5em; margin-left: -10px">{{ sousmodule.libelle }}</span>
							<template v-slot:actions v-if="user.role === 'ADMIN'">
								<button v-on:click="modif(sousmodule,'module')" class="btn btn-sm"
										:class="[sousmodule.actif ?'btn-success':'btn-danger']">
									<i class="fas fa-check" v-if="sousmodule.actif"></i>
									<i class="fas fa-times" v-else></i>
								</button>
								<v-icon color="primary">$expand</v-icon>
							</template>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<div v-for="(c,index2) in sousmodule.cours" :key="index2">
								<div style="display: flex;" v-if="user.role === 'ADMIN' || c.actif || user.idUser===c.idFormateur">
									<span @click="changeRoute(module.ordreN1,c)" class="lien" :style="{color:user.idUser===c.idFormateur?'orangered':'auto'}">
										{{module.ordreN1===2?'Cours '+(index2+1)+':':''}} {{ c.libelle }}
									</span>

									<div style="margin-left: auto" v-if="user.role==='ADMIN'">
										<button v-on:click="modif(c,'cours')" class="btn px-3 py-0 ma-1"
												:class="[c.actif ?'btn-success':'btn-danger']">
											<i class="fas fa-check" v-if="c.actif"></i>
											<i class="fas fa-times" v-else></i>
										</button>
									</div>
								</div>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</div>

<!--			<div class="module">-->
<!--				<router-link to="/niveau3" style="color: black">-->
<!--					<div class="nomModule">Niveau 3</div>-->
<!--					<span>Cliquez ici pour accéder au niveau 3</span>-->
<!--				</router-link>-->
<!--			</div>-->

		</div>
	</div>
</template>

<script>


import myaxios from "@/service/myaxios";
// import '../../node_modules/animate.css/animate.css';

export default {
	name: 'App',
	data: () =>({
		modules: [],
		cours: [],
		sousModuleActu:null,
		libelleModule: '',
		niveauActu: null,
		typeModule: "3",
	}),
	methods: {
		modif(elem,type){
			const action = !elem.actif
			myaxios.patch("/api/"+type+"/"+(action?'activation/':'desactivation/')+elem["id"+type.charAt(0).toUpperCase()+type.slice(1)])
				.then(()=>{
					elem.actif=action
				})
		},
		changeRoute(typeModule,cours) {
			if (typeModule===3 && cours.libelle==="Niveau 3")
				this.$router.push({name: 'Niveau3'})
			else if (this.user.role==='ADMIN' || (this.user.role==='FORMATEUR' && this.user.idUser===cours.idFormateur))
				this.$router.push({name: 'BoardCP', params: {id: cours.idCours}})
			else {
				if (typeModule === 3)
					this.$router.push({name: 'Cas_pratique', params: {id: cours.idCours}})
				else
					this.$router.push({name: 'Cours_show', params: {id: cours.idCours}})
			}
		},
		creerNiveau() {
			myaxios.post("/api/cours/",{
				idModule:this.sousModuleActu.idModule,
				nCP:this.sousModuleActu.cours.length+1
			}).then((response)=>{
				console.log(response.data)
				this.$router.push({name: 'BoardCP', params: {id: response.data.idCours}})
			})
		},
		async dupliquerNiveau() {
			const res=await myaxios.post("/api/cours/", {
				idModule: this.sousModuleActu.idModule,
				libelle: "Nouveau niveau de cas pratique",
				nModule: this.sousModuleActu.ordreN2,
				nCP: this.sousModuleActu.cours.length + 1
			})
			const data = await myaxios.get("/api/caspratique/data/" + this.niveauActu.idCours)
			myaxios.put("/api/caspratique/"+res.data.data.idCours,data.data.cp)
				.then(() => {
					this.$router.push({name: 'BoardCP', params: {id: res.data.data.idCours}})
				})
				.catch(() => {alert("Erreur lors de la création")})
		},
		creerCP(){
			const module = this.modules.find(m=>m.ordreN1===parseInt(this.typeModule))
			myaxios.post("/api/module",{libelle:this.libelleModule,position:module.sousModules.length+1})
				.then((response)=> {
					response.data.cours=[]
					module.sousModules.push(response.data)
				}).catch((error)=>{
				console.log(error)
			})
		}
	},
	computed: {
		user() {
			return this.$store.state.user
		},
		listeCoursEtCP() {
			const liste=[]
			this.modules.forEach(m=> {
				if (m.ordreN1===3||m.ordreN1===2)
					liste.push(...m.sousModules?.reduce((acc, m) =>
						acc.concat(m.cours.map(c => ({...c, libelle: m.libelle + " - " + c.libelle}))), []))
			})
			return liste
		},
		listeModuleCours(){
			return this.modules.reduce((acc,m)=> acc.concat(m.sousModules),[])
		}
	},
	mounted() {
		myaxios.get("/api/cours")
			.then((response) => {
				var res = response.data.data
				res.filter(m => m.ordreN2 === 0).forEach(m => {
					m.sousModules = res.filter(m2 => m2.ordreN1 === m.ordreN1 && m2.ordreN2 !== 0)
					m.sousModules.forEach(m2 => {
						res.splice(res.indexOf(m2), 1)
						m2.cours = m2.cours.sort((a,b)=> a.idCours-b.idCours)
					})
					m.sousModules=m.sousModules.filter(m=>this.user.role === 'ADMIN'||m.actif)
				})
				this.modules = res.filter(m => m.sousModules.length !== 0)
			})
			.catch(() => {})
	}
}

</script>

<style lang="scss" scoped>

@import "../assets/scss/variables";

.nomcourstransition-move {
	transition: transform 1s;
}

.coursWrapper {
	/* Y X */
	margin: 3% auto;
	max-width: 1000px;
}

h1 {
	font-family: $big-font;
	text-align: left;
	margin-top: 7%;
}

.module {
	border-style: solid;
	border-width: 0 0 3px 5px;
	border-color: #0d47a1;
}

.lien {
	color: #0d47a1;
	font-size: 1.2em;
	cursor: pointer;
}

.nomModule {
	font-size: 2.5em
}

@media screen and (max-width: 500px) {
	.nomModule {
		font-size: 2em
	}
}

</style>
