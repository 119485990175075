<!--<script src="../../config/prod.env.js"></script>-->
<!--<script src="../../config/index.js"></script>-->

<template>
	<div class="connexion">
		<transition name="switchconnexion" mode="out-in">
			<div class="connexion-wrapper page-component">
				<div class="form-wrapper">
					<h2 style="text-align: center">Connexion</h2>
					<form class="formconnexion" @submit="login">
						<input class="inp-email" name="email" type="text" minlength="2" maxlength="128" placeholder="E-mail"
							   required/>
						<input class="inp-mdp" name="password" type="password" minlength="2" maxlength="128"
							   placeholder="Mot de passe" required/>

						<p style="textAlign: center">Mot de passe oublié ?<br/>
							<a href="#">cliquez-ici</a>
						</p>

						<button class="btn" type="submit">Se connecter</button>
					</form>
				</div>

				<div class="explication">
					<h2>Bienvenue</h2>
					<p>Pour pouvoir accéder au contenu, vous devez être connecté afin de profiter de l’enseignement de SEO-ELP.</p>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import myaxios from "@/service/myaxios";
import {reconnexionOK} from "@/service/myaxios";
import router from "@/router";

export default {
	name: "connexionView",
	data(){
		return {
			taille: window.innerWidth
		}
	},
	methods: {
		login(e) {
			e.preventDefault();
			let email = e.target.elements.email.value;
			let password = e.target.elements.password.value;
			myaxios.post("/api/auth/connexionVUE", {
				email: email,
				password: password
			}).then((response) => {
				response.data.user.token = response.data.token;
				response.data.user.role = {1:"DEMO",2:"ADMIN",3:"FORMATEUR",4:"ETUDIANT"}[response.data.user.idRole];
				this.$cookies.set("currentUser", response.data.user, "2h");
				if(this.$store.state.user.idUser!==response.data.user.idUser) //permet la reinistialisation du store utilise pour les cas pratiques
					this.$store.commit("resetState")
				this.$store.state.user = response.data.user
				if (this.$store.state.user.role === "ADMIN")
					router.push("/admin")
				else
					router.push("/home")
			})
				.catch((err) => {
					if (err.response.status === 401 && err.response.data.success===1)//utilisé pour les demonstrations
						alert("Une demonstration est en cours sur la plateforme, les comptes sont bloqués pour le moment.\n" +
							"Ils seront de nouveau disponible dans quelques heures.")
					else
						alert("Impossible de se connecter. Vérifiez vos identifiants.")
				})
		}
	},created(){
		reconnexionOK();
	}
};
</script>

<style lang="scss" scoped>

@import "../assets/scss/_variables.scss";

.connexion{
	.btn {
		font-family: $big-font;
		font-weight: 500;
		background: $primary-color;
		color: white;
		border-radius: 35px;
		padding: 10px 24px;
		font-size: 1.3em;
	}
}

.page-component{
	display: flex;
}

.form-wrapper {
	color: $primary-color;
	width: 100%;

	form {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: 300px;

		input {
			margin: 0 0 5% 0;
			width: 100%;
			padding: 15px 15px 15px 40px;
			border: none;
			outline: none;
			box-shadow: rgba($color: #a0a0a0, $alpha: 0.5) 4px 4px 10px;
			background-position: 10px 10px;
			background-repeat: no-repeat;
			background-size: 22px auto;

			&.inp-email {
				background-image: url("../assets/elements/img_form/img_email.png");
			}
			&.inp-mdp {
				background-image: url("../assets/elements/img_form/img_mdp.png");
			}
			&.inp-nom {
				background-image: url("../assets/elements/img_form/img_nom.png");
			}
		}
	}
}

.explication {
	background-color: #0d47a1;
	color: white;
	text-align: center;
	padding: 1px 10%;
	 p {
		font-size: 1.2em;
	}
}

@media screen and (min-width: 600px) {
	.connexion{
		height: 100%;
	}
	.connexion > .page-component {
		width: 100%;
		> * {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-content: center;
		}

		.explication {
			width: 30%;
			align-items: center
		}
	}
}
@media screen and (max-width: 600px){
	.page-component{
		flex-wrap: wrap;
		height: 100%;
	}
}
</style>

