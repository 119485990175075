import { render, staticRenderFns } from "./AccueilBack.vue?vue&type=template&id=dd71e752&scoped=true&"
import script from "./AccueilBack.vue?vue&type=script&lang=js&"
export * from "./AccueilBack.vue?vue&type=script&lang=js&"
import style0 from "./AccueilBack.vue?vue&type=style&index=0&id=dd71e752&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd71e752",
  null
  
)

export default component.exports