<script src="../../assets/js/app.js"></script>
<template>
	<div class="fond_accueil" v-scrollanimation>
		<canvas id="canvas"></canvas>
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1650 1050">
			<defs>
				<filter id="blur">
					<feGaussianBlur stdDeviation="0"/>
				</filter>
				<filter id="blur2">
					<feGaussianBlur stdDeviation="2"/>
				</filter>
			</defs>
			<path
				class="cls-1"
				d="M942,247.2l-25.84-14.92,11.34-27.6a11,11,0,0,0-20.35-8.36l-10.22,24.86-23.28-13.44a11,11,0,0,0-11,19.06l25.85,14.92-11.35,27.6a11,11,0,0,0,6,14.35,10.86,10.86,0,0,0,4.18.83,11,11,0,0,0,10.18-6.82l10.22-24.86L931,266.26a11,11,0,1,0,11-19.06Z"
			/>
			<path
				class="cls-2"
				d="M374,225.2l-25.84-14.92,11.34-27.6a11,11,0,0,0-20.35-8.36l-10.22,24.86-23.28-13.44a11,11,0,0,0-11,19.06l25.85,14.92-11.35,27.6a11,11,0,0,0,6,14.35,10.86,10.86,0,0,0,4.18.83,11,11,0,0,0,10.18-6.82l10.22-24.86L363,244.26a11,11,0,1,0,11-19.06Z"
			/>
			<path
				class="cls-3"
				d="M592,838.2l-25.84-14.92,11.34-27.6a11,11,0,0,0-20.35-8.36l-10.22,24.86-23.28-13.44a11,11,0,0,0-11,19.06l25.85,14.92-11.35,27.6a11,11,0,0,0,6,14.35,10.86,10.86,0,0,0,4.18.83,11,11,0,0,0,10.18-6.82l10.22-24.86L581,857.26a11,11,0,0,0,11-19.06Z"
			/>
			<circle
				class="cls-2"
				cx="1549.81"
				cy="456"
				r="29.5"
			/>
			<circle
				class="cls-4"
				cx="327.81"
				cy="775"
				r="29.5"
			/>
			<circle
				class="cls-4"
				cx="845.81"
				cy="39"
				r="29.5"
			/>
			<path
				class="cls-1"
				d="M100.81,74.5A29.5,29.5,0,1,1,71.31,104a29.5,29.5,0,0,1,29.5-29.5m0-18a47.5,47.5,0,1,0,47.5,47.5,47.55,47.55,0,0,0-47.5-47.5Z"
			/>
			<path
				class="cls-2"
				d="M1548.81,771.5a29.5,29.5,0,1,1-29.5,29.5,29.5,29.5,0,0,1,29.5-29.5m0-18a47.5,47.5,0,1,0,47.5,47.5,47.55,47.55,0,0,0-47.5-47.5Z"
			/>
			<path
				class="cls-3"
				d="M587.56,147.54A63,63,0,1,1,622.89,26.6l-5,17.28a45,45,0,0,0-25.24,86.39Z"
			/>
			<path
				class="cls-3"
				d="M1610.56,201.54A63,63,0,1,1,1645.89,80.6l-5.05,17.28a45,45,0,0,0-25.24,86.39Z"
			/>
			<path
				class="cls-3"
				d="M1010.56,1014.54a63,63,0,1,1,35.33-120.94l-5.05,17.28a45,45,0,0,0-25.24,86.39Z"
			/>
			<path
				class="cls-2"
				d="M1425.5,137.16l-32.84,3.24c-1.56-16-15-28.9-31.35-28.9a30.74,30.74,0,0,0-15.67,4.31h0a64.56,64.56,0,0,1-89.32-51.29v0a29.9,29.9,0,0,0-.44-5.12A32.11,32.11,0,0,0,1224.31,33a31.4,31.4,0,0,0-11.7,2.25L1200.36,4.61a64.23,64.23,0,0,1,24-4.61,65.08,65.08,0,0,1,44.54,17.66,64.28,64.28,0,0,1,20,38.9A31.5,31.5,0,0,0,1332,85.77a30.51,30.51,0,0,0,2.85-1.31A69.36,69.36,0,0,1,1361.31,79a62.79,62.79,0,0,1,18.45,2.51A64.69,64.69,0,0,1,1425.5,137.16Z"
			/>
			<path
				class="cls-1"
				d="M1178.52,725.33l33-1.18c.56,16.05,13.18,29.79,29.48,30.81a30.72,30.72,0,0,0,15.91-3.33h0a64.56,64.56,0,0,1,85.94,56.78v0a29.34,29.34,0,0,0,.12,5.14,32.11,32.11,0,0,0,29.85,28.3,31.42,31.42,0,0,0,11.82-1.52l10.31,31.35a64.27,64.27,0,0,1-24.2,3.1,65.06,65.06,0,0,1-43.34-20.41,64.2,64.2,0,0,1-17.49-40.07,32,32,0,0,0-.11-5.19,31.5,31.5,0,0,0-41.12-26.66,30.54,30.54,0,0,0-2.93,1.13,69.22,69.22,0,0,1-26.8,3.79,62.58,62.58,0,0,1-18.26-3.66A64.69,64.69,0,0,1,1178.52,725.33Z"
			/>
			<path
				class="cls-1"
				d="M0,937.15l9.71-22.79c11.09,4.74,24.45.54,30.34-10.21a23.16,23.16,0,0,0,2.59-7.76,22.62,22.62,0,0,0,.22-4.11h0a48.46,48.46,0,0,1,66-40.3h0a22.46,22.46,0,0,0,3.53,1.56,24.1,24.1,0,0,0,28.75-11.26,23.57,23.57,0,0,0,2.74-8.51l24.59,3a48.28,48.28,0,0,1-5.6,17.43,48.84,48.84,0,0,1-27.7,23,48.22,48.22,0,0,1-32.8-.89,24.33,24.33,0,0,0-3.56-1.58,23.67,23.67,0,0,0-28.5,11,23.25,23.25,0,0,0-2.73,8.46,20.47,20.47,0,0,0-.17,2.35,52,52,0,0,1-6,19.42,46.77,46.77,0,0,1-8.31,11.24A48.55,48.55,0,0,1,0,937.15Z"
			/>
			<path
				class="cls-3"
				d="M32.24,445.24l23.5,13c-6.35,11.43-3.31,26.31,7.64,33.87a24.8,24.8,0,0,0,8.08,3.66,24.54,24.54,0,0,0,4.41.7h0A52.56,52.56,0,0,1,112,572.2v0a23.86,23.86,0,0,0-2.07,3.63,26.13,26.13,0,0,0,8.94,32.28,25.34,25.34,0,0,0,8.88,3.91l-6,26.2a52.4,52.4,0,0,1-18.18-8A53,53,0,0,1,81.91,597.8a52.35,52.35,0,0,1,4.63-35.3,26.34,26.34,0,0,0,2.1-3.66A25.65,25.65,0,0,0,71.15,523c-.84-.19-1.67-.34-2.52-.45a56.45,56.45,0,0,1-20.29-8.6,51.27,51.27,0,0,1-11.2-10.22A52.67,52.67,0,0,1,32.24,445.24Z"
			/>
		</svg>
	</div>
</template>

<script>
export default {
	methods: {
		transitionChange() {
			setTimeout(function () {
				var path = document.querySelectorAll("svg>*");
				for (var i = 0; i < path.length; i++) {
					path[i].style.transition = "all 0.05s ease";
				}
			}, 1000);
		},
	},
	mounted() {
		this.transitionChange();
	},
};
</script>

<style lang="scss" scoped>

@import "../assets/scss/variables";

.fond_accueil {
	@media screen and (max-width: 500px) {
		display: none;
	}

	transform: none;
	height: 100vh;
	width: 100vw;
	svg {
		width: 100%;
		height: 100%;
		padding: 10vh 10vw;
	}
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.6s ease-out;
	transition-delay: 0.3s;

	&.before-enter {
		svg {
			path,
			circle {
				&:nth-child(2n) {
					transform: rotate(-80deg) scale(0.5);
				}

				&:nth-child(2n + 1) {
					transform: rotate(90deg) scale(0.5);
				}
			}

			opacity: 0;
			transform: scale(5);
		}
	}

	#canvas {
		position: absolute;
	}

	svg {
		@include vsm {
		}
		@include sm {
		}
		@include md {
			font-size: 6em;
		}
		@include lg {
			font-size: 8em;
		}
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 1;
		transition: all 1s cubic-bezier(0, 0.68, 0.37, 1);

		path,
		circle {
			transform: scale(1);
			transform-origin: center;
			transition: all 1s cubic-bezier(0, 0.68, 0.37, 1);

			&:nth-child(2n) {
				filter: url(#blur);
			}

			&:nth-child(2n + 1) {
				filter: url(#blur2);
			}
		}

		.cls-1 {
			fill: #f25270;
		}

		.cls-2 {
			fill: #41d9a1;
		}

		.cls-3 {
			fill: #f29f04;
		}

		.cls-4 {
			fill: #3d54d9;
		}
	}
}
</style>
