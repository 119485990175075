<template>
	<div class="contact">
		<div class="form-wrapper">
			<h1>Contactez-nous</h1>

			<form v-on:submit.prevent="sendEmail()" id="formContact" style="margin-left: 20px">
				<input class="inp-text" name="email" type="text" placeholder="Outil de contact (mail, téléphone, ...)" required v-if="!user.username"/>
				<p v-else style="font-size: 1.5em">Vous êtes connectés sous le compte de : {{user.username}}</p>
				<input class="inp-text" name="sujet" type="text" placeholder="Sujet" required autofocus />
				<textarea class="inp-text" name="message" type="text" placeholder="Message" required />
				<v-checkbox label=" En soumettant ce formulaire, j'accepte que les informations saisies soient utilisées dans le cadre de ma demande.
				Conformément au RGPD, vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en nous contactant par ce formulaire."
							required v-if="!user.username"></v-checkbox>

				<button type="submit" value="send">Envoyer</button>
			</form>
		</div>

		<div id="popupSign" v-if="type" v-on:click="this.closePopup">
			<div class="popupWrapper">
				<i class="mdi mdi-check-circle" style="font-size: 80px; color: green" v-if="type==='ok'"></i>
				<i class="mdi mdi-alert-circle" style="font-size: 80px; color: red" v-if="type==='err'"></i>
				<p style="text-align: center; padding: 20px">{{ messageSubmit }}</p>
				<v-btn>Fermer</v-btn>
			</div>
		</div>

		<aside></aside>
	</div>
</template>

<script>

import myaxios from "@/service/myaxios";

export default {
	name: "ContactView",
	data: function () {
		return {
			type: null,
			messageSubmit: ""
		};
	},
	computed: {
		user() {
			return this.$store.state.user;
		}
	},
	methods: {
		sendEmail() {
			let myForm = document.getElementById('formContact');
			let formData = new FormData(myForm);
			const data = {};
			for (let [key, val] of formData.entries()) {
				Object.assign(data, {[key]: val})
			}

			myaxios.post("/api/message", {expediteur:this.user.username||data.email,sujet:data.sujet,contenu:data.message,interne:this.user.role!==undefined})
				.then(() => {
					this.messageSubmit = "Votre message à bien été envoyé !";
					this.type = "ok";
				})
				.catch((errors) => {
					console.log(errors)
					this.messageSubmit = "Votre message n'a pas pu être envoyé, veuillez réessayer plus tard.";
					this.type="err"
				})
		},
		validEmail: function (email) {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email);
		},
		closePopup() {
			this.type = null;
		},
	},
};
</script>

<style lang="scss" scoped>

@import "../assets/scss/_variables.scss";

.contact {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  @include sm {
    aside {
      display: none;
    }
    .form-wrapper {
      max-width: 90vw;
    }
  }
  @include lg {
    aside {
      display: block;
    }
    .form-wrapper {
      max-width: 60%;
    }
  }
  #popupSign {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    .popupWrapper {
      width: 50vh;
      background: white;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.4);
      img {
        width: 30%;
        padding: 60px 0 20px 0;
      }
      p {
        padding: 60px 0;
        font-family: $med-font;
      }
    }
  }
  .form-wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    margin: 0 auto;
    h1 {
      font-size: 3em;
      font-family: $big-font;
      color: $alert-color;
      margin-bottom: 8%;
    }
    form {
      display: flex;
      width: 80%;
      flex-direction: column;
      .inp-text:focus {
        border: none;
        border-bottom: 4px solid $alert-color;
      }
      .inp-text::placeholder {
        opacity: 1;
        color: black;
      }
      .inp-text {
        outline: none;
        margin-bottom: 20px;
        border: none;
        border-bottom: 2px solid black;
        transition: all 0.1s ease;
        padding: 10px 0;
      }
      textarea.inp-text {
        resize: none;
        height: 150px;
      }
      button {
        cursor: pointer;
        margin-top: 7%;
        color: $alert-color;
        border: none;
        background: none;
        width: fit-content;
        padding: 20px 20px 20px 80px;
        text-transform: uppercase;
        font-size: 1.2em;
        font-family: $med-font;
        background-image: url("../assets/elements/buttonContact.svg");
        background-position: left;
        background-size: 55px auto;
        background-repeat: no-repeat;
      }
    }
  }
  aside {
    width: 40%;
    background: url("../assets/elements/img_contact.png");
    background-position: right;
    background-size: cover;
    height: 100vh;
  }
}
</style>
